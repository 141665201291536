import React, { Component } from 'react'
import headImg from 'img/hands_abordo.png'
import { Layout } from 'core/components'
import { ABanner, AAnimate } from 'shared/components/common'
import { Alliance } from 'modules'

interface LoginHeaderProps {
  title?: string
}

interface AlliancesProps {
  pageContext: {
    locale: string
  }
}
interface AlliancesState {
  mounted: boolean
}

class Alliances extends Component<AlliancesProps & LoginHeaderProps, AlliancesState> {
  state = {
    mounted: false
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({ mounted: true })
    }, 0)
  }
  render() {
    const {
      pageContext: { locale }
    } = this.props
    const { title } = this.props
    const { mounted } = this.state
    return (
      <Layout locale={locale}>
        <AAnimate type="fadeIn" in={mounted}>
          <ABanner
            title={title || 'Nuestras alianzas'}
            titleSize={9}
            titleClassName="big-white"
            content={<img className="img-fluid" src={headImg} />}
          />
          <Alliance />
        </AAnimate>
      </Layout>
    )
  }
}
export default Alliances
